const EventEmitter = require('events');

class Reloader extends EventEmitter {
  constructor() {
    super();

    this.lastFired = false;
    this.timerId = false;
    this.running = false;
  }

  start(seconds = 10) {
    if (this.running) {
      return;
    }

    this.running = true;
    this.lastFired = new Date().getTime();
    this.timerId = setInterval(() => {
      const now = new Date().getTime();
      if (now - this.lastFired > seconds * 1000) {
        this.emit('focus');
      }
      this.lastFired = now;
    }, 500);
  }

  pause() {
    clearInterval(this.timerId);
    this.running = false;
  }
}

module.exports = Reloader;
