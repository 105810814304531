import { api, screen } from '../js/globals';
import Static from './static';
import './event-code.scss';

const EventCode = {
  checkin: {},
  init: () => {
    const eventCodeScreen = document.querySelector('.screen.event-code');

    eventCodeScreen.querySelector('.back').onclick = () => {
      screen.moveTo('home');
    };

    eventCodeScreen.querySelector('form').onsubmit = async submit => {
      submit.preventDefault();

      const code = eventCodeScreen.querySelector('input').value;

      // Validate Code on api
      const validation = await api.validateEventCode(code, EventCode.checkin.show_entries_id)
        .catch(() => EventCode.alert('Código incorrecto!'));

      // Check-In Bypass
      const show = validation[0];

      const result = await api.checkIn(
        show.show_id,
        show.show_date,
        api.user.user_id,
        show.show_entries_id
      );

      Static.setEvent(show);

      // Validate
      result && !result.error
        ? screen.moveTo('static')
        : EventCode.alert('Código incorrecto!');
    };

    // Focus Code input
    eventCodeScreen.querySelector('input').focus();
  },

  alert: message => {
    // eslint-disable-next-line no-alert
    alert(message);
  },

  fill: data => {
    EventCode.checkin = data;
  },
};
module.exports = EventCode;
