import io from 'socket.io-client';
import constants from './constants';

const EventEmitter = require('events');

class Socket extends EventEmitter {
  constructor() {
    super();

    // Server endoint
    this.server = constants.protocol + constants.server;

    const socket = io(this.server, {
      path: '/commedia_socket',
    });

    socket.on('connect', () => {
      this.emit('connect', {
        id: socket.id,
      });
    });

    socket.on('disconnect', () => {
      this.emit('disconnect', {
        id: socket.id,
      });
    });

    this.socket = socket;
  }

  listen(event, id, callback) {
    this.socket.on(event + id, callback);
  }

  ignore(event, id, callback) {
    this.socket.off(event + id, callback);
  }

  // Request when comming from backgrouns
  // eslint-disable-next-line camelcase
  requestVotingInBackground(user_id, show_id, show_entries_id) {
    this.socket.emit('requestVotingInBackground',
      {
        user_id,
        show_id,
        show_entries_id,
      });
    return;
    // First attempt
    setTimeout(() => {
      this.socket.emit('requestVotingInBackground',
        {
          user_id,
          show_id,
          show_entries_id,
        });
    }, 4e3);

    // Second attempt
    setTimeout(() => {
      this.socket.emit('requestVotingInBackground',
        {
          user_id,
          show_id,
          show_entries_id,
        });
    }, 8e3);
  }

  // Send entry
  // eslint-disable-next-line camelcase
  sendEntry(user_id, show_id, show_entries_id, entity_id, subentity_id, voting_sequence_id) {
    // TODO validate data
    this.socket.emit('receiveVotingFromUser', [
      {
        user_id,
        show_id,
        show_entries_id,
        entity_id,
        subentity_id,
        voting_sequence_id,
      },
    ]);
  }

  // Send comment to server
  // eslint-disable-next-line camelcase
  sendComment(comment, user_id, show_entries_id) {
    // TODO validate data
    this.socket.emit('receiveCommentFromUser',
      {
        comment,
        show_entries_id,
        user_id,
      });
  }
}

module.exports = Socket;
