module.exports = {
  hide: elem => elem.classList.add('hidden'),
  show: elem => elem.classList.remove('hidden'),

  indexOf: node => {
    let index = -1;
    while (node) {
      node = node.previousElementSibling;
      index += 1;
    }
    return index;
  },

  template: template => template.content.cloneNode(true),
};
