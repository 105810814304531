import './css/styles.scss';
import '../node_modules/normalize.css/normalize.css';

// Screens
import Intro from './views/intro';
import Home from './views/home';
import EventCode from './views/event-code';
import Static from './views/static';

// Libs
import Socket from './js/socket';
import API from './js/api';
import Screen from './js/screen-manager';
import Reloader from './js/reloader';
import Storage from './js/storage';

const api = new API();
const socket = new Socket();
const screen = new Screen();
const reloader = new Reloader();
const storage = new Storage();

module.exports = {
  api,
  socket,
  screen,
  reloader,
  storage,
};

// --------------------
// Screens
// --------------------

Intro.init();
Home.init();
EventCode.init();
Static.init();

// CSS VH Trick
const onResize = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
window.addEventListener('resize', onResize);
onResize();

// Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('sw.js');
}
