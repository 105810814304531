import APIController from './api-controller';
import { storage } from './globals';

class API extends APIController {
  /**
   * Guest Login
   */
  loginGuest() {
    return new Promise(
      (resolve, reject) => {
        if (storage.exists()) {
          this.post(
            'auth/refresh-tokens',
            { refreshToken: storage.refreshToken }
          ).then(e => {
            this.setToken(e.tokens.access.token);
            this.user = storage.data.user;

            // Store it localy
            storage.save(storage.data.user, e.tokens.access.token, e.tokens.refresh.token);

            resolve(this.user);
          }).catch(() => {
            reject();
          });
        } else {
          this.get('auth/guest-user-login').then(data => {
            // Save Token
            this.setToken(data.tokens.access.token);

            // Save user
            this.user = data.user;

            // Store it localy
            storage.save(data.user, data.tokens.access.token, data.tokens.refresh.token);

            resolve(this.user);
          }).catch(reject);
        }
      }
    );
  }

  /**
   * Validate Event Code
   * @param {string} code
   */
  // eslint-disable-next-line camelcase
  validateEventCode(event_code, show_entries_id) {
    return this.post(
      'showEntry/validate-event-code',
      { event_code, show_entries_id },
      { Authorization: this.bearerToken }
    );
  }

  /**
   * Check In
   */
  // eslint-disable-next-line camelcase
  checkIn(show_id, show_date, user_id, show_entries_id) {
    return this.post(
      'showEntry/checkin-bypass',
      {
        show_id,
        show_date,
        user_id,
        show_entries_id,
      },
      { Authorization: this.bearerToken }
    );
  }

  /**
   * Get show Entry
   */
  getShowEntry() {
    return this.get(
      'showEntry/get-showentry',
      {},
      { Authorization: this.bearerToken }
    );
  }
}

module.exports = API;
