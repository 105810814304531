module.exports = {
  get socket() {
    return module.bundle.cache['index.js'].exports.socket;
  },

  get screen() {
    return module.bundle.cache['index.js'].exports.screen;
  },

  get api() {
    return module.bundle.cache['index.js'].exports.api;
  },

  get reloader() {
    return module.bundle.cache['index.js'].exports.reloader;
  },

  get storage() {
    return module.bundle.cache['index.js'].exports.storage;
  },
};
