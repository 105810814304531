import {
  socket, api, screen, reloader,
} from '../js/globals';
import { template } from '../js/utils';
import './static.scss';

const Static = {
  selectedEntry: false,
  votingSequenceId: false,
  waiting: false,
  awaiking: false,
  lostFocus: false,
  init: () => {

  },

  setEvent: show => {
    const showPanel = name => {
      // Hide all
      document.querySelectorAll('.screen.static > ul li').forEach(li => li.classList.add('hidden'));

      // Show Panel
      const panel = document.querySelector(`.screen.static ul li.${name}`);
      panel.classList.remove('hidden');

      Static.waiting = name === 'main';

      return panel;
    };

    const shuffle = d => {
      for (let c = d.length - 1; c > 0; c -= 1) {
        const b = Math.floor(Math.random() * (c + 1));
        const a = d[c];
        d[c] = d[b];
        d[b] = a;
      }
      return d;
    };

    showPanel('main');

    const loadVoting = data => {
      // eslint-disable-next-line prefer-destructuring
      data = data.data[0];
      if (!data) return;

      Static.votingSequenceId = data.voting_sequence_id;
      // Show Panel
      const panel = showPanel(data.type);

      // Set Menu
      panel.querySelector('.menu').innerHTML = data.title;
      panel.querySelectorAll('ul li')?.forEach(li => li.remove());

      switch (data.type) {
        default:
        case 'list': {
          const list = shuffle(data.dataset);
          list.forEach(entry => {
            const elem = template(panel.querySelector('template'));
            elem.querySelector('img').src = entry.wall_image;
            elem.querySelector('h1').innerHTML = entry.title;
            elem.querySelector('p').innerHTML = entry.subtitle;

            const button = elem.querySelector('button');
            button.onclick = () => {
              panel.querySelectorAll('li > button').forEach(e => e.classList.add('inverted'));
              button.classList.remove('inverted');
              Static.selectedEntry = entry;
            };

            panel.querySelector('.content ul').appendChild(elem);
          });
          break;
        }

        case 'text': {
          const list = shuffle(data.dataset);
          list.forEach(entry => {
            const elem = template(panel.querySelector('template'));
            elem.querySelector('p').innerHTML = entry.title;

            const div = elem.querySelector('div');
            div.onclick = () => {
              panel.querySelectorAll('li > div').forEach(e => e.classList.remove('active'));
              div.classList.add('active');
              Static.selectedEntry = entry;
            };

            panel.querySelector('.content ul').appendChild(elem);
          });
          break;
        }

        case 'emoji':
          data.dataset.forEach(entry => {
            const elem = template(panel.querySelector('template'));
            elem.querySelector('img').src = entry.wall_image;
            elem.querySelector('p').innerHTML = entry.title;

            const div = elem.querySelector('div');
            div.onclick = () => {
              panel.querySelectorAll('li > div').forEach(e => e.classList.remove('active'));
              div.classList.add('active');
              Static.selectedEntry = entry;
            };

            panel.querySelector('.content ul').appendChild(elem);
          });
          break;
      }
      panel.querySelector('button').onclick = e => {
        e.preventDefault();

        // TODO validate data
        if (Static.selectedEntry) {
          socket.sendEntry(
            Static.selectedEntry.user_id,
            Static.selectedEntry.show_id,
            Static.selectedEntry.show_entries_id,
            Static.selectedEntry.entity_id,
            Static.selectedEntry.subentity_id,
            Static.votingSequenceId
          );

          // Go to main
          showPanel('main');
        } else {
          Static.alert('Selecione um opção');
        }
      };
      Static.awaiking = false;
    };

    socket.listen('receiveVotingContentForEvent', show.show_entries_id, loadVoting);

    socket.listen('receiveVotingContentInBackground', (show.show_entries_id.toString() + api.user.user_id.toString()), loadVoting);

    socket.listen('receiveVotingCompletion', show.show_entries_id, () => {
      showPanel('main');
    });

    socket.listen('pickADrawResult', show.show_entries_id, data => {
      if (api.user.user_id === data.data.user_id) showPanel('pick');
    });

    socket.listen('pickACommentResult', show.show_entries_id, () => {
      showPanel('main');
    });

    socket.listen('sendCommentBoxToUsers', show.show_entries_id, () => {
      const panel = showPanel('comment');
      const textArea = panel.querySelector('textarea');

      // Hide popup
      panel.querySelector('.popup').classList.add('hidden');

      // Submit comment
      panel.querySelector('button').onclick = e => {
        e.preventDefault();

        if (textArea.value) {
          socket.sendComment(
            textArea.value,
            api.user.user_id,
            show.show_entries_id
          );

          // Clear textarea
          textArea.value = '';

          // Show popup
          panel.querySelector('.popup').classList.remove('hidden');
        } else {
          Static.alert('Introduza um comentário');
        }
      };

      // Comment close button
      panel.querySelector('.popup button').onclick = () => showPanel('main');
    });

    socket.listen('receiveVotingEndEvent', show.show_entries_id, () => {
      const panel = showPanel('end');

      panel.querySelector('button').onclick = () => {
        reloader.pause();
        screen.moveTo('home');
      };
    });

    socket.on('connect', id => {
      console.log(`connected again ${Static.lostFocus}`);
      if (!Static.lostFocus) { return; }
      socket.requestVotingInBackground(
        api.user.user_id,
        show.show_id,
        show.show_entries_id, id
      );
      Static.lostFocus = false;
    });

    // Pick a draw button
    document.querySelector('.screen.static .pick button').onclick = () => showPanel('main');

    reloader.on('focus', () => {
      Static.lostFocus = true;
    });

    reloader.start(5);
  },

  alert: message => {
    // eslint-disable-next-line no-alert
    alert(message);
  },
};
module.exports = Static;
