class ScreenManager {
  constructor() {
    this.app = document.querySelector('.app');
    this.screens = this.app.querySelectorAll('.screen');
  }

  moveTo(screen) {
    // Clear active
    this.screens.forEach(s => s.classList.remove('active'));

    // Active selected screen
    this.app.querySelector(`.screen.${screen}`).classList.add('active');
  }
}

module.exports = ScreenManager;
