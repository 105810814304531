import './home.scss';

import { screen } from '../js/globals';
import { indexOf, template } from '../js/utils';
import EventCode from './event-code';

const Home = {
  init: () => {
    const homeScreen = document.querySelector('.screen.home');
    const menu = homeScreen.querySelector('.menu');
    const panel = homeScreen.querySelector('.panel');

    // Menu Buttons
    menu.querySelectorAll('li').forEach(li => {
      li.onclick = () => {
        // Change menu active item
        menu.querySelectorAll('.active').forEach(active => active.classList.remove('active'));
        li.classList.add('active');

        // Change panel page
        const index = indexOf(li);
        panel.style.setProperty('--page', index);
      };
    });
  },

  fill: data => {
    const panel = document.querySelector('.screen.home .panel');

    const [checkInData, upcomingData, actorsData, previousData] = data;

    // ----------
    // Check in
    const checkInPanel = panel.querySelector('.checkin');
    const checkInTemplate = checkInPanel.querySelector('template');

    checkInPanel.style.setProperty('--total', checkInData.dataset.length);

    checkInData.dataset.forEach(checkin => {
      const elem = template(checkInTemplate);
      elem.querySelector('.location').innerText = checkin.location_title;
      elem.querySelector('.date').innerText = (new Intl.DateTimeFormat('pt')).format(new Date(checkin.show_date));
      elem.querySelector('.seats').innerText = checkin.occupied_seat;
      elem.querySelector('img').src = checkin.poster_image;

      // Go to Event Code on Check-In click
      elem.querySelector('button').onclick = () => {
        EventCode.fill(checkin);
        screen.moveTo('event-code');
      };

      checkInPanel.querySelector('.slider').appendChild(elem);
    });

    // ----------
    // Upcoming
    const upcomingPanel = panel.querySelector('.upcoming');
    const upcomingTemplate = upcomingPanel.querySelector('template');

    upcomingPanel.style.setProperty('--total', upcomingData.dataset.length);

    upcomingData.dataset.forEach(upcoming => {
      const elem = template(upcomingTemplate);
      elem.querySelector('.location').innerText = upcoming.location_title;
      elem.querySelector('.date').innerText = (new Intl.DateTimeFormat('pt')).format(new Date(upcoming.show_date));
      elem.querySelector('.seats').innerText = upcoming.occupied_seat;
      elem.querySelector('img').src = upcoming.poster_image;
      upcomingPanel.querySelector('.slider').appendChild(elem);
    });

    // ----------
    // Actors
    const actorPanel = panel.querySelector('.actor');
    const actorTemplate = actorPanel.querySelector('template');

    actorPanel.style.setProperty('--total', actorsData.dataset.length);

    actorsData.dataset.forEach(actor => {
      const elem = template(actorTemplate);
      elem.querySelector('h1').innerText = actor.title;
      elem.querySelector('img').src = actor.wall_image;
      actorPanel.querySelector('.slider').appendChild(elem);
    });

    // ----------
    // Previous
    const previousPanel = panel.querySelector('.previous');
    const previousTemplate = previousPanel.querySelector('template');

    previousPanel.style.setProperty('--total', previousData.dataset.length);

    previousData.dataset.forEach(previous => {
      const elem = template(previousTemplate);
      elem.querySelector('.location').innerText = previous.location_title;
      elem.querySelector('.date').innerText = (new Intl.DateTimeFormat('pt')).format(new Date(previous.show_date));
      elem.querySelector('.seats').innerText = previous.occupied_seat;
      elem.querySelector('img').src = previous.poster_image;
      previousPanel.querySelector('.slider').appendChild(elem);
    });
  },
};

module.exports = Home;
