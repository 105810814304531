class Storage {
  constructor() {
    this.load();
  }

  load() {
    this.data = JSON.parse(window.localStorage.getItem('data'));
  }

  save(user, token, refreshToken) {
    window.localStorage.setItem('data', JSON.stringify({
      user,
      token,
      refreshToken,
    }));

    this.load();
  }

  exists() {
    return !!this.data;
  }

  clear() {
    window.localStorage.removeItem('data');
    this.data = null;
  }

  get user() {
    return this.data?.user;
  }

  get token() {
    return this.data?.token;
  }

  get refreshToken() {
    return this.data?.refreshToken;
  }
}

module.exports = Storage;
