import constants from './constants';

const EventEmitter = require('events');

/**
 * @class
 * Class representing the API Controller
 * @extends {EventEmitter}
 *
 * Emits:
 * - Error
 */
class APIController extends EventEmitter {
  /**
   * @constructs APIController
   */
  constructor() {
    super();

    // Server endoint
    this.url = constants.protocol + constants.server + constants.api;
  }

  /**
   * Make a generic GET request to the API
   * @param {string} path
   * @param {object} data
   * @param {object} headers
   * @returns {promise} Promise with the get form response
   */
  get(path, data = {}, headers = {}) {
    return this.call(path, data, headers, 'get');
  }

  /**
   * Make a generic POST request to the API
   * @param {string} path
   * @param {object} data
   * @param {object} headers
   * @returns {promise} Promise with the post form response
   */
  post(path, data = {}, headers = {}) {
    return this.call(path, data, headers, 'post');
  }

  /**
   * Make a generic PUT request to the API
   * @param {string} path
   * @param {object} data
   * @param {object} headers
   * @returns {promise} Promise with the post form response
   */
  put(path, data = {}, headers = {}) {
    return this.call(path, data, headers, 'put');
  }

  /**
   * Performs a call to the API
   * @param {string} path
   * @param {object} data
   * @param {object} headers
   * @param {string} method
   * @return {promise} Promise with the response data
   */
  call(path, data = {}, headers = {}, method = 'get') {
    const formData = new FormData();

    // Default headers
    const defaultHeaders = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    Object.entries(headers).forEach(([key, value]) => defaultHeaders.append(key, value));

    const args = {
      headers: defaultHeaders,
      method,
      credentials: 'same-origin',
    };

    let queryParams = '';

    switch (method) {
      default:
      case 'get':
        queryParams = `?${new URLSearchParams(formData).toString()}`;
        break;
      case 'put':
      case 'post':
        args.body = JSON.stringify(data);
        break;
    }

    return new Promise((resolve, reject) => {
      fetch(`${this.url}${path}${queryParams}`, args)
        .then(response => response.json().then(result => {
          result.error
            ? reject(result.error ? result.message : null)
            : resolve(result.data);
        }))
        .catch(exception => {
          reject(exception);
        });
    });
  }

  /**
   * Sets a token for authentication
   * @param {string} token
   */
  setToken(token) {
    if (token) this.bearerToken = `Bearer ${token}`;
  }
}

module.exports = APIController;
