import { show, hide } from '../js/utils';
import {
  api, screen, socket, storage,
} from '../js/globals';
import './intro.scss';
import Home from './home';

module.exports = {
  init: () => {
    const intro = document.querySelector('.screen.intro');
    const button = intro.querySelector('button');
    const loading = intro.querySelector('.loading');
    let initialized = false;

    // On socket connect
    socket.on('connect', () => {
      hide(loading);
      show(button);

      button.onclick = () => {
        hide(button);
        show(loading);

        // Guest login
        api.loginGuest()
          .then(() => {
            api.getShowEntry().then(e => {
              Home.fill(e);
              screen.moveTo('home');
            });
          })
          .catch(() => {
            // Token maybe expired
            storage.clear();

            // Retry
            button.click();
          })
          .finally(() => {
            hide(loading);
          });
      };

      // Auto login after first login
      if (!initialized) {
        storage.exists() && button.click();
        initialized = true;
      }
    });
  },
};
